import { useCallback } from 'react';
import { Button } from 'react-vant';
import { Photo, Down } from '@react-vant/icons';

import './index.css';

const Header = () => {
  const handleUpload = useCallback(() => {

  },[])

  const handleDownload = useCallback(() => {

  },[])

  return <div className='headerWrapper'>
    <div className='headerLogo'>
      <h3>ImaheEditor</h3>
    </div>
    <div className='headerActions'>
      <Button icon={<Photo />} onClick={handleUpload} type='primary' size='small'>加载图片</Button>
      <Button icon={<Down  />} onClick={handleDownload} type='primary' size='small'>下载</Button>
    </div>

  </div>
}

export default Header