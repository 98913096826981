export const pyStyles= [
  {
    label: "带音调",
    value: "STYLE_TONE",
  },
  {
    label:"不带音调",
    value:'STYLE_NORMAL'
  },
  {
    label:"汉字拼音后标记声调",
    value:"STYLE_TONE2",
  },
  {
    label:"拼音字母后标记声调",
    value:"STYLE_TO3NE",
  },
  {
    label:"汉字拼音首个音节",
    value:"STYLE_INITIALS",
  },
  {
    label:"汉字拼音首个单字母",
    value:"STYLE_FIRST_LETTER",
  }
]

export const pyModes = [
  {
    label:"普通模式",
    value:"normal"
  },
  {
    label:"姓氏模式",
    value:"surname"
  }
]

export const pySegments = [
  {
    label:'Segmenter(默认)',
    value:'Intl.Segmenter'
  },
  {
    label:'segmentit',
    value:'segmentit'
  },
  {
    label:'nodejieba(仅支持node)',
    value:'nodejieba'
  }
]

export const boolOptions = [
  {
    label:"是",
    value: true
  },
  {
    label:"否",
    value: false
  }
]