import { isUndefined, isNull } from 'lodash-es'

export const isExisty = (param) => !isUndefined(param) && !isNull(param);

/**
 * Get object's properties
 * @param {Object} obj - object
 * @param {Array} keys - keys
 * @returns {Object} properties object
 */
export const getProperties = (obj, keys) => {
  const props = {};
  const { length } = keys;
  let i = 0;
  let key;

  for (i = 0; i < length; i += 1) {
    key = keys[i];
    props[key] = obj[key];
  }

  return props;
}

export const forEachOwnProperties = (obj, iteratee, context) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (iteratee.call(context || null, obj[key], key, obj) === false) {
        break;
      }
    }
  }
}