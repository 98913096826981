import { useState, useCallback } from 'react';
import { Input, Layout, Button, Form, Card,message, QRCode } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import qs from 'qs'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'; 
import './index.css';

const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 4 },
  },
};
const EncodeUrl = () => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage();

  const [completedUrl, setCompletedUrl] = useState('');

  const getDecodedUrl = useCallback((inputUrl) => {
    try{
      if(inputUrl){
        const result = new URL(inputUrl)
        return result
      }else{
        return {}
      }
    } catch(error){
      messageApi.error('输入的URL内容不合法')
      return {}
    }
  },[messageApi])

  const handleEncode = useCallback(({ inputUrl, inputQuery = [] }) => {
    const { origin = '', pathname = '', search = '', hash = '' } = getDecodedUrl(inputUrl)
    const queryObj = inputQuery.reduce((pre,{ key, value}) => {
      pre[key] = value;
      return pre
    },{})

    try{
      let result = ''
      if(search){
        const searchStr = `?${ qs.stringify({
            ...qs.parse(search.slice(1) || ''),
            ...queryObj
          },{ arrayFormat: 'comma'  })}`
        result = `${origin}${pathname}${searchStr}${hash}`
      }else{
        result = `${origin}${pathname}?${qs.stringify(queryObj,{ arrayFormat: 'comma'  })}${hash}`
      }
      if(result === '?'){
        setCompletedUrl('')
        return
      }
      setCompletedUrl(result)
    } catch(error){
      messageApi.error(error)
    }

  },[getDecodedUrl,messageApi])

  const handleReset = useCallback(() => {
    form.resetFields();
    setCompletedUrl('');
  },[form])

  const handleFinish = useCallback(() => {
    form
      .validateFields({validateOnly: true})
      .then((values) => {
        handleEncode(values)
      })
      .catch(() => {

      })
  },[form, handleEncode])

  return <Layout className='EncodeUrl'>
    {contextHolder}
    <Card title="输入URL参数" extra="地址内容里包含的 query 会与对象设置的内容合并处理">
      <Form 
        form={form}
        layout="horizontal"
        {...formItemLayout}
        style={{ width: '100%'}}
      >
      <Form.Item label="URL 地址" name="inputUrl" rules={[{whitespace: true,}]}>
        <TextArea
          rows={2} 
          placeholder='请输入 URL 地址'
          style={{ width: 'calc(100% - 8px)'}}
        />
      </Form.Item>
      <Form.List
        name="inputQuery"
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? 'query 部分' : ''}
                required={false}
                key={field.key}
                style={{ marginBottom: 0}}
              >
                <Form.Item
                  {...field}
                  name={[field.name, 'key']}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "请输入 key",
                    },
                  ]}
                  style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                >
                  <Input placeholder="query item key"/>
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, 'value']}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "请输入 value",
                    },
                  ]}
                  style={{ display: 'inline-block', width: 'calc(70% - 8px)', marginLeft: 2 }}
                >
                  <Input placeholder="query item value" />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button
                type="primary"
                onClick={() => add()}
                style={{ width: 'calc(100% - 8px)' }}
                icon={<PlusOutlined />}
              >
                添加 Query Item
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
    </Card>
    <Card title="生成链接">
      <Button type="primary" onClick={handleReset}>重置</Button>
      <Button type="primary" onClick={handleFinish} style={{ marginLeft: '12px'}}>生成</Button>
      { completedUrl && <CopyToClipboard text={completedUrl}
          onCopy={() => message.success('复制成功')}>
          <Button type='primary' style={{ marginLeft: '12px' }}>复制结果</Button>
        </CopyToClipboard>}
      <p style={{ padding: '8px', backgroundColor:'#eee' }}><code>{completedUrl || '暂无'}</code></p>
      { completedUrl && <QRCode value={completedUrl} />}
    </Card>
  </Layout>
  }

export default EncodeUrl;