// fabric 仅在此处用到
import { fabric } from 'fabric';
import extend from 'lodash-es/extend'
import CropperDrawingMode from './mode/cropper';
import FreeDrawingMode from './mode/freeDrawing';
import LineDrawingMode from './mode/lineDrawing';
import ShapeDrawingMode from './mode/shape';
import TextDrawingMode from './mode/text';
import IconDrawingMode from './mode/icon';
import ZoomDrawingMode from './mode/zoom';
import ResizeDrawingMode from './mode/resize';
import ImageLoader from './module/imageLoader';
import Cropper from './module/cropper';
import Flip from './module/flip';
import Rotation from './module/rotation';
import FreeDrawing from './module/freeDrawing';
import Line from './module/line';
import Text from './module/text';
import Icon from './module/icon';
import Filter from './module/filter';
import Shape from './module/shape';
import Zoom from './module/zoom';
import Resize from './module/resize';
import { stamp } from './utils/common';
import { componentNames, eventNames, fObjectOptions, drawingModes } from './utils/constant';
import { getProperties } from './utils/type';
import CustomEvents from './CustomEvents';
import { makeSelectionUndoData, makeSelectionUndoDatum, setCachedUndoDataForDimension } from './helper/selectionModifyHelper';

const cssOnly = {
  cssOnly: true,
};
const backstoreOnly = {
  backstoreOnly: true,
};

const CONTAINER_CLASSNAME = 'image-editor-container'
class Graphics {
  constructor(element, { cssMaxWidth, cssMaxHeight } = {}) {
    /**
     * Fabric image instance
     * @type {fabric.Image}
     */
    this.canvasImage = null;
    /**
     * target fabric object for copy paste feature
     * @type {fabric.Object}
     * @private
     */
    this.targetObjectForCopyPaste = null;
    /**
     * Fabric-Canvas instance
     * @type {fabric.Canvas}
     * @private
     */
    this._canvas = null;
    /**
     * Component map
     * @type {Object.<string, Component>}
     * @private
     */
    this._componentMap = {};
    /**
     * Object Map
     * @type {Object}
     * @private
     */
    this._objects = {};
    /**
     * Image name
     * @type {string}
     */
    this.imageName = '';
    // 事件集合
    this._handler = null
    /**
     * DrawingMode map
     * @type {Object.<string, DrawingMode>}
     * @private
     */
    this._drawingModeMap = {};

    // new 时初始化
    this.init(element);
  }

  init(element){
    this.initHandler();
    this.initCanvas(element);
  }

  initCanvas(element){
    // 关闭 fabric objectCaching
    this._setObjectCachingToFalse();
    // 根据 element 创建 fabric canvas 
    this._setCanvasElement(element);
    // 注册 drawing mode
    this._createDrawingModeInstances();
    // 注册组件
    this._createComponents();
    // 注册 Canvas 事件
    this._attachCanvasEvents();
    // 注册缩放事件
    this._attachZoomEvents();
  }

  initHandler(){
    /**
     * fabric event handlers
     * @type {Object.<string, function>}
     * @private
     */
    this._handler = {
      // 鼠标点击事件处理【TODO】
      onMouseDown: this._onMouseDown.bind(this),
      // 添加元素事件处理
      onObjectAdded: this._onObjectAdded.bind(this),
      // 删除元素事件处理
      onObjectRemoved: this._onObjectRemoved.bind(this),
      // 移动元素处理
      onObjectMoved: this._onObjectMoved.bind(this),
      // 缩放元素处理
      onObjectScaled: this._onObjectScaled.bind(this),
      // 元素修改事件处理
      onObjectModified: this._onObjectModified.bind(this),
      // 元素旋转事件处理
      onObjectRotated: this._onObjectRotated.bind(this),
      // 元素选中事件处理
      onObjectSelected: this._onObjectSelected.bind(this),
      // path:created 事件处理
      onPathCreated: this._onPathCreated.bind(this),
      // selected clearrd 事件处理
      onSelectionCleared: this._onSelectionCleared.bind(this),
      // selected created 事件处理
      onSelectionCreated: this._onSelectionCreated.bind(this),
    };
  }
    /**
   * Create components
   * @private
   */
  // 注册能力项
    _createComponents() {
      this._register(this._componentMap, new ImageLoader(this));
      this._register(this._componentMap, new Cropper(this));
      this._register(this._componentMap, new Flip(this));
      this._register(this._componentMap, new Rotation(this));
      this._register(this._componentMap, new FreeDrawing(this));
      this._register(this._componentMap, new Line(this));
      this._register(this._componentMap, new Text(this));
      this._register(this._componentMap, new Icon(this));
      this._register(this._componentMap, new Filter(this));
      this._register(this._componentMap, new Shape(this));
      this._register(this._componentMap, new Zoom(this)); //TODO: QA
      this._register(this._componentMap, new Resize(this));
    }
  /**
   * Creates DrawingMode instances
   * @private
   */
  // TODO 注册 mode
    _createDrawingModeInstances() {
      this._register(this._drawingModeMap, new CropperDrawingMode());
      this._register(this._drawingModeMap, new FreeDrawingMode());
      this._register(this._drawingModeMap, new LineDrawingMode());
      this._register(this._drawingModeMap, new ShapeDrawingMode());
      this._register(this._drawingModeMap, new TextDrawingMode());
      this._register(this._drawingModeMap, new IconDrawingMode());
      this._register(this._drawingModeMap, new ZoomDrawingMode());
      this._register(this._drawingModeMap, new ResizeDrawingMode());
    }

      /**
   * Attach canvas's events
   */
  _attachCanvasEvents() {
    const canvas = this._canvas;
    const handler = this._handler;
    canvas.on({
      'mouse:down': handler.onMouseDown,
      'object:added': handler.onObjectAdded,
      'object:removed': handler.onObjectRemoved,
      'object:moving': handler.onObjectMoved,
      'object:scaling': handler.onObjectScaled,
      'object:modified': handler.onObjectModified,
      'object:rotating': handler.onObjectRotated,
      'path:created': handler.onPathCreated,
      'selection:cleared': handler.onSelectionCleared,
      'selection:created': handler.onSelectionCreated,
      'selection:updated': handler.onObjectSelected,
    });
  }
    /**
   * Get a DrawingMode instance
   * @param {string} modeName - DrawingMode Class Name
   * @returns {DrawingMode} DrawingMode instance
   * @private
   */
    _getDrawingModeInstance(modeName) {
      return this._drawingModeMap[modeName];
    }
  /**
   * Register component
   * @param {Object} map - map object
   * @param {Object} module - module which has getName method
   * @private
   */
  _register(map, module) {
    map[module.getName()] = module;
  }
  /**
   * Set object caching to false. This brought many bugs when draw Shape & cropzone
   * @see http://fabricjs.com/fabric-object-caching
   * @private
   */
  _setObjectCachingToFalse() {
    fabric.Object.prototype.objectCaching = false;
  }
  /**
   * Set canvas element to fabric.Canvas
   * element 是 canvas 会直接使用，不是的话会在 element 新建 canvas 并且使用
   * @param {Element|string} element - Wrapper or canvas element or selector
   * @private
   */
  _setCanvasElement(element) {
    let selectedElement;
    let canvasElement;

    if (element.nodeType) {
      selectedElement = element;
    } else {
      selectedElement = document.querySelector(element);
    }
    // 检查 selectedElement
    if(!selectedElement){
      throw Error('element 不存在！')
    }

    if (selectedElement.nodeName.toUpperCase() !== 'CANVAS') {
      canvasElement = document.createElement('canvas');
      selectedElement.appendChild(canvasElement);
    }

    this._canvas = new fabric.Canvas(canvasElement, {
      containerClass:CONTAINER_CLASSNAME,
      enableRetinaScaling: false,
    });
  }
  /**
   * Get fabric.Canvas instance
   * @returns {fabric.Canvas}
   */
  getCanvas() {
    return this._canvas;
  }
   /**
   * Returns canvas element of fabric.Canvas[[lower-canvas]]
   * @returns {HTMLCanvasElement}
   */
   getCanvasElement() {
    return this._canvas.getElement();
  }
  /**
   * Get canvasImage (fabric.Image instance)
   * @returns {fabric.Image}
   */
  getCanvasImage() {
    return this.canvasImage;
  }
  /**
   * Get component
   * @param {string} name - Component name
   * @returns {Component}
   */
  getComponent(name) {
    return this._componentMap[name];
  }
  /**
   * Get image name
   * @returns {string}
   */
  getImageName() {
    return this.imageName;
  }
  /**
   * Get an object by id
   * 通过 ID 获取 object
   * @param {number} id - object id
   * @returns {fabric.Object} object corresponding id
   */
  getObject(id) {
    return this._objects[id];
  }
  /**
   * Get an id by object instance
   * 通过 object 获取对应的 ID
   * @param {fabric.Object} object object
   * @returns {number} object id if it exists or null
   */
  getObjectId(object) {
    let key = null;
    for (key in this._objects) {
      if (this._objects.hasOwnProperty(key)) {
        if (object === this._objects[key]) {
          return key;
        }
      }
    }

    return null;
  }
  /**
   * Gets an active object or group
   * 获取正在被激活的 object 或 group
   * @returns {Object} active object or group instance
   */
  getActiveObject() {
    return this._canvas?._activeObject;
  }
  /**
   * Gets an active group object
   * @returns {Object} active group object instance
   */
   getActiveObjects() {
    const activeObject = this._canvas._activeObject;

    return activeObject && activeObject.type === 'activeSelection' ? activeObject : null;
  }
    /**
   * Verify that you are ready to erase the object.
   * @returns {boolean} ready for object remove
   */
    isReadyRemoveObject() {
      const activeObject = this.getActiveObject();
  
      return activeObject && !activeObject.isEditing;
    }
    /**
   * Get current drawing mode
   * @returns {string}
   */
    getDrawingMode() {
      return this._drawingMode;
    }
      /**
   * Get the current drawing mode is same with given mode
   * @param {string} mode drawing mode
   * @returns {boolean} true if same or false
   */
  _isSameDrawingMode(mode) {
    return this.getDrawingMode() === mode;
  }
  
    /**
     * Start a drawing mode. If the current mode is not 'NORMAL', 'stopDrawingMode()' will be called first.
     * @param {String} mode Can be one of <I>'CROPPER', 'FREE_DRAWING', 'LINE', 'TEXT', 'SHAPE'</I>
     * @param {Object} [option] parameters of drawing mode, it's available with 'FREE_DRAWING', 'LINE_DRAWING'
     *  @param {Number} [option.width] brush width
     *  @param {String} [option.color] brush color
     * @returns {boolean} true if success or false
     */
    startDrawingMode(mode, option) {
      if (this._isSameDrawingMode(mode)) {
        return true;
      }
  
      // If the current mode is not 'NORMAL', 'stopDrawingMode()' will be called first.
      this.stopDrawingMode();
  
      const drawingModeInstance = this._getDrawingModeInstance(mode);
      if (drawingModeInstance && drawingModeInstance.start) {
        drawingModeInstance.start(this, option);
  
        this._drawingMode = mode;
      }
  
      return !!drawingModeInstance;
    }
  
    /**
     * Stop the current drawing mode and back to the 'NORMAL' mode
     */
    stopDrawingMode() {
      if (this._isSameDrawingMode(drawingModes.NORMAL)) {
        return;
      }
  
      const drawingModeInstance = this._getDrawingModeInstance(this.getDrawingMode());
      if (drawingModeInstance && drawingModeInstance.end) {
        drawingModeInstance.end(this);
      }
      this._drawingMode = drawingModes.NORMAL;
    }
  /**
   * Remove an object in array yb id
   * @param {number} id - object id
   */
  _removeFabricObject(id) {
    delete this._objects[id];
  }
  /**
   * Get text object's properties
   * @param {fabric.Object} obj - fabric text object
   * @param {Object} props - properties
   * @returns {Object} properties object
   */
  _createTextProperties(obj) {
    const predefinedKeys = [
      'text',
      'fontFamily',
      'fontSize',
      'fontStyle',
      'textAlign',
      'textDecoration',
      'fontWeight',
    ];
    const props = {};
    extend(props, getProperties(obj, predefinedKeys));

    return props;
  }
  /**
   * Return object's properties
   * @param {fabric.Object} obj - fabric object
   * @returns {Object} properties object
   */
  createObjectProperties(obj) {
    const predefinedKeys = [
      'left',
      'top',
      'width',
      'height',
      'fill',
      'stroke',
      'strokeWidth',
      'opacity',
      'angle',
    ];
    const props = {
      id: stamp(obj),
      type: obj.type,
    };

    extend(props, getProperties(obj, predefinedKeys));

    if (['i-text', 'text'].includes(obj.type)) {
      extend(props, this._createTextProperties(obj, props));
    } else if (['rect', 'triangle', 'circle'].includes(obj.type)) {
      const shapeComp = this.getComponent(componentNames.SHAPE);
      extend(props, {
        fill: shapeComp.makeFillPropertyForUserEvent(obj),
      });
    }

    return props;
  }
  /**
   * Add object array by id
   * @param {fabric.Object} obj - fabric object
   * @returns {number} object id
   */
  _addFabricObject(obj) {
    // 获得一个唯一 ID
    const id = stamp(obj);
    // 把 obj 挂载到 this._objects 上
    this._objects[id] = obj;
    // 返回 id
    return id;
  }
  /**
   * Callback function after loading image
   * 加载完图片之后绘制到画布上
   * @param {fabric.Image} obj - Fabric image object
   * @private
   */
  _callbackAfterLoadingImageObject(obj) {
    const centerPos = this.getCanvasImage().getCenterPoint();

    obj.set(fObjectOptions.SELECTION_STYLE);
    obj.set({
      left: centerPos.x,
      top: centerPos.y,
      crossOrigin: 'Anonymous',
    });

    this.getCanvas().add(obj).setActiveObject(obj);
  }
  /**
   * Add image object on canvas
   * @param {string} imgUrl - Image url to make object
   * @returns {Promise}
   */
  addImageObject(imgUrl) {
    const callback = this._callbackAfterLoadingImageObject.bind(this);

    return new Promise((resolve) => {
      fabric.Image.fromURL(
        imgUrl,
        (image) => {
          callback(image);
          resolve(this.createObjectProperties(image));
        },
        {
          crossOrigin: 'Anonymous',
        }
      );
    });
  }
  /**
   * Returns the object ID to delete the object.
   * @returns {number} object id for remove
   */
  getActiveObjectIdForRemove() {
    const activeObject = this.getActiveObject();
    const { type, left, top } = activeObject;
    const isSelection = type === 'activeSelection';

    if (isSelection) {
      // isSelection? 为什么要 new Group? 是因为如果 object 是 group 中的一员，就会有 type:activeSelection 属性吗
      // 然后处理方式是选中对应 group 来删除？还是只删除它自己
      const group = new fabric.Group([...activeObject.getObjects()], {
        left,
        top,
      });

      return this._addFabricObject(group);
    }

    return this.getObjectId(activeObject);
  }

  /**
   * Get Active object Selection from object ids
   * @param {Array.<Object>} objects - fabric objects
   * @returns {Object} target - target object group
   */
  getActiveSelectionFromObjects(objects) {
    const canvas = this.getCanvas();

    return new fabric.ActiveSelection(objects, { canvas });
  }
  /**
   * Save image(background) of canvas
   * @param {string} name - Name of image
   * @param {?fabric.Image} canvasImage - Fabric image instance
   */
  setCanvasImage(name, canvasImage) {
    if (canvasImage) {
      // canvasImage 加上 __fe_id 属性
      stamp(canvasImage);
    }
    this.imageName = name;
    this.canvasImage = canvasImage;
  }
  /**
   * Set image properties
   * {@link http://fabricjs.com/docs/fabric.Image.html#set}
   * @param {Object} setting - Image properties
   * @param {boolean} [withRendering] - If true, The changed image will be reflected in the canvas
   */
  setImageProperties(setting, withRendering) {
    if (!this.canvasImage) {
      return;
    }

    this.canvasImage.set(setting).setCoords();
    if (withRendering) {
      this._canvas.renderAll();
    }
  }
  /**
   * Set canvas dimension - css only
   *  {@link http://fabricjs.com/docs/fabric.Canvas.html#setDimensions}
   * @param {Object} dimension - Canvas css dimension
   */
  setCanvasCssDimension(dimension) {
    this._canvas.setDimensions(dimension, cssOnly);
  }
  /**
   * Set canvas dimension - backstore only
   *  {@link http://fabricjs.com/docs/fabric.Canvas.html#setDimensions}
   * @param {Object} dimension - Canvas backstore dimension
   */
  setCanvasBackstoreDimension(dimension) {
    this._canvas.setDimensions(dimension, backstoreOnly);
  }
  /**
   * Create fabric static canvas
   * @returns {Object} {{width: number, height: number}} image size
   */
  createStaticCanvas() {
    const staticCanvas = new fabric.StaticCanvas();

    staticCanvas.set({
      enableRetinaScaling: false,
    });

    return staticCanvas;
  }
  /**
   * Lazy event emitter
   * @param {string} eventName - event name
   * @param {Function} paramsMaker - make param function
   * @param {Object} [target] - Object of the event owner.
   * @private
   */
  _lazyFire(eventName, paramsMaker, target) {
    const existEventDelegation = target && target.canvasEventDelegation;
    const delegationState = existEventDelegation ? target.canvasEventDelegation(eventName) : 'none';

    if (delegationState === 'unregistered') {
      target.canvasEventRegister(eventName, (object) => {
        this.fire(eventName, paramsMaker(object));
      });
    }

    if (delegationState === 'none') {
      this.fire(eventName, paramsMaker(target));
    }
  }
  /**
   * "object:added" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
    _onObjectAdded(fEvent) {
      const obj = fEvent.target;
      if (obj.isType('cropzone')) {
        return;
      }
  
      this._addFabricObject(obj);
    }
    /**
     * "object:removed" canvas event handler
     * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
     * @private
     */
    _onObjectRemoved(fEvent) {
      const obj = fEvent.target;
  
      this._removeFabricObject(stamp(obj));
    }
    /**
     * "object:moving" canvas event handler
     * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
     * @private
     */
    _onObjectMoved(fEvent) {
      this._lazyFire(
        eventNames.OBJECT_MOVED,
        (object) => this.createObjectProperties(object),
        fEvent.target
      );
    }
  
    /**
     * "object:scaling" canvas event handler
     * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
     * @private
     */
    _onObjectScaled(fEvent) {
      this._lazyFire(
        eventNames.OBJECT_SCALED,
        (object) => this.createObjectProperties(object),
        fEvent.target
      );
    }

  /**
   * "object:modified" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onObjectModified(fEvent) {
    const { target } = fEvent;
    if (target.type === 'activeSelection') {
      const items = target.getObjects();

      items.forEach((item) => item.fire('modifiedInGroup', target));
    }

    this.fire(eventNames.OBJECT_MODIFIED, target, this.getObjectId(target));
  }

  /**
   * "object:rotating" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onObjectRotated(fEvent) {
    this._lazyFire(
      eventNames.OBJECT_ROTATED,
      (object) => this.createObjectProperties(object),
      fEvent.target
    );
  }
  /**
   * "mouse:down" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onMouseDown(fEvent) {
    const { e: event, target } = fEvent;
    const originPointer = this._canvas.getPointer(event);

    if (target) {
      const { type } = target;
      const undoData = makeSelectionUndoData(target, (item) =>
        makeSelectionUndoDatum(this.getObjectId(item), item, type === 'activeSelection')
      );

      setCachedUndoDataForDimension(undoData);
    }

    this.fire(eventNames.MOUSE_DOWN, event, originPointer);
  }
    /**
   * "object:selected" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
    _onObjectSelected(fEvent) {
      const { target } = fEvent;
      const params = this.createObjectProperties(target);
  
      this.fire(eventNames.OBJECT_ACTIVATED, params);
    }
  
    /**
     * "path:created" canvas event handler
     * @param {{path: fabric.Path}} obj - Path object
     * @private
     */
    _onPathCreated(obj) {
      const { x: left, y: top } = obj.path.getCenterPoint();
      obj.path.set(
        extend(
          {
            left,
            top,
          },
          fObjectOptions.SELECTION_STYLE
        )
      );
  
      const params = this.createObjectProperties(obj.path);
  
      this.fire(eventNames.ADD_OBJECT, params);
    }
  
    /**
     * "selction:cleared" canvas event handler
     * @private
     */
    _onSelectionCleared() {
      this.fire(eventNames.SELECTION_CLEARED);
    }
  
    /**
     * "selction:created" canvas event handler
     * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
     * @private
     */
    _onSelectionCreated(fEvent) {
      const { target } = fEvent;
      const params = this.createObjectProperties(target);
  
      this.fire(eventNames.OBJECT_ACTIVATED, params);
      this.fire(eventNames.SELECTION_CREATED, fEvent.target);
    }

  /**
   * Attach zoom events
   */
  _attachZoomEvents() {
    const zoom = this.getComponent(componentNames.ZOOM);
    zoom.attachKeyboardZoomEvents();
  }

  /**
   * To data url from canvas
   * @param {Object} options - options for toDataURL
   *   @param {String} [options.format=png] The format of the output image. Either "jpeg" or "png"
   *   @param {Number} [options.quality=1] Quality level (0..1). Only used for jpeg.
   *   @param {Number} [options.multiplier=1] Multiplier to scale by
   *   @param {Number} [options.left] Cropping left offset. Introduced in fabric v1.2.14
   *   @param {Number} [options.top] Cropping top offset. Introduced in fabric v1.2.14
   *   @param {Number} [options.width] Cropping width. Introduced in fabric v1.2.14
   *   @param {Number} [options.height] Cropping height. Introduced in fabric v1.2.14
   * @returns {string} A DOMString containing the requested data URI.
   */
  toDataURL(options) {
    const cropper = this.getComponent(componentNames.CROPPER);
    cropper.changeVisibility(false);

    const dataUrl = this._canvas && this._canvas.toDataURL(options);
    cropper.changeVisibility(true);

    return dataUrl;
  }
}

Object.assign(Graphics.prototype, CustomEvents)

export default Graphics;