import { isUndefined, isNull } from 'lodash-es'

/**
* 生成一个用不重复的ID
* @param { Number } randomLength 
*/
export const getUUID = (randomLength = 6) => {
 return Number(Math.random().toString().substr(2,randomLength) + Date.now()).toString(36)
}

// 全局 fabric object ID 计数器
let globalId = 0;

// 给 obj 打上 ID，并且返回这个 ID
export function stamp(obj) {
  if (!obj.__fe_id) {
    globalId += 1;
    obj.__fe_id = globalId;
  }

  return obj.__fe_id;
}

/**
 * Make key-value object from arguments
 * @returns {object.<string, string>}
 */
export function keyMirror(...args) {
  const obj = {};

  args.forEach((key) => {
    obj[key] = key;
  });

  return obj;
}
