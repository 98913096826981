import React, { useRef, useState } from 'react';
import { Input, Button, Modal, Alert, Card, QRCode, Collapse } from 'antd';
import { debounce } from 'lodash-es';
import ReactJson from 'react-json-view';
import './URLPrettier.css';

const { Panel } = Collapse;
const URLPrettier = () => {
  const inputRef = useRef();

  const [url, setUrl] = useState('');
  const [error, setError] = useState();
  const [parseResult, setParseResult] = useState({});

  const handleUrlChange = (e) => {
    console.log('handleUrlChange==>')
    const { value } = e.target
    setUrl(value.trim())
  }

  const getSearchParams = (searchParams) => {
    const result = {};
    for(let [key, value] of searchParams){
      result[key] = value;
    }
    return result;
  }

  const formatUrl = (url = '') => {
    let result = url.trim();
    if(result.length === 0){
      setError('请输入URL内容');
      return '';
    }

    if([`"`,`'`].includes(url[0])){
      result = result.slice(1)
    }
    if([`"`,`'`].includes(url[url.length -1])){
      result = result.slice(0, url.length-2)
    }

    return result;
  }

  const parse = (url) => {
    const relUrl = formatUrl(url);
    if(!relUrl){
      return;
    }

    setUrl(relUrl);
    // console.log('relUrl===>', relUrl)
    try{
      const { origin, pathname, port, searchParams, hash} = new URL(relUrl);
      const parseResult = {
        path:`${origin}${port ? `:${port}` : ''}${pathname ? `/${pathname}` : ''}`,
        queryObj: getSearchParams(searchParams),
        hash
      }
      // console.log(parseResult);
      setParseResult(parseResult);
      setError('')
    } catch(error){
      setError(error)
    }
  }

  const handleParse = () => {
    parse(url)
  }

  const handleDecode = () => {
    const relUrl = formatUrl(url);
    if(relUrl){
      const decodedUrl = decodeURIComponent(relUrl);
      setUrl(decodedUrl);
      inputRef.current.focus();
      setError(undefined);
    }
  }

  const handleQrcode = () => {
    Modal.info({
      title: 'URL对应二维码',
      content: (
        <QRCode value={url || '-'}/>
      )
    });
  }

  const { path, queryObj, hash} = parseResult;
  return <>
    <div className='URLPrettier-header'>
      <h4 style={{ margin: '4px',   lineHeight: '38px'}}>URL Prettier</h4>
    </div>
    <div className='URLPrettier-content'>
      <Card
        bordered={false}
        title="输入 URL"
        style={{ width:'100%'}} 
        extra={ 
          error && <Alert 
            type="error" 
            message={String(error)} 
            banner 
            style={{ marginLeft: '12px'}}
          />
        }
      >
        <Input
          ref={inputRef}
          value={url}
          onChange={handleUrlChange}
          placeholder="请输入 URL 字符串"
          style={{ width: '100%'}}
          size="large"
          allowClear
        />
        <div className='URLPrettier-actions'>
          <Button type="primary" onClick={handleDecode}>Decode</Button>
          <Button type="primary" onClick={handleParse}>解析</Button>
          <Button type="primary" onClick={handleQrcode}>展示二维码</Button>
        </div>
      </Card>
      <Card bordered={false} title="解析结果" style={{ width:'100%',marginTop: '12px'}}>
      <Collapse defaultActiveKey={['path','query','hash']}>
        <Panel key="path" header="Path">
          {path}
        </Panel>
        <Panel key="query" header="Query">
          <ReactJson src={queryObj} name="Query" displayDataTypes={false}/>
        </Panel>
        <Panel key="hash" header="hash">
          {hash}
        </Panel>
      </Collapse>
      </Card>
    </div>
  </>
  }

export default React.memo(URLPrettier);