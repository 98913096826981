// import { useState } from 'react';
import { Layout } from 'antd';
import URLPrettier from '../../component/URLPrettier/URLPrettier';
import './URLPrettierPage.css'

const URLPrettierPage = () => {

  return <Layout className='URLPrettier'>
    <URLPrettier />
  </Layout>
  }

export default URLPrettierPage;