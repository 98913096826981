// 转 base64
export const stringToBase64 = (str) => {
  return btoa(str);
}

// 解析 base64
export const base64ToObject = (base64) => {
  const decoded = atob(base64);
  const json = JSON.parse(decoded);
  return json;
}

// 解析 base64 字符串
export const base64ToString = (base64) => {
  const decoded = atob(base64);
  return decoded;
}