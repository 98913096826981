import ImageEditorReact from '../../../../packages/image-editor-react'
import Actions from './components/Actions';
import Header from './components/Header';

import './style.css';

const ImageEditorPage = () => {
  return <div className='imageEditorPage'>
    <Header />
    <ImageEditorReact />
    <Actions />
  </div>
}

export default ImageEditorPage;