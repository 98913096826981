import { useState } from 'react';
import { Input, Layout, Button, Popover, Alert, Modal } from 'antd';
import { VerticalLeftOutlined, VerticalRightOutlined, QuestionCircleOutlined } from '@ant-design/icons'; 
import { debounce } from 'lodash-es';
import ReactJson from 'react-json-view';
import './JSONPrettier.css';
import URLPrettier from '../../component/URLPrettier/URLPrettier';

const { TextArea } = Input;
const JSONPrettier = () => {
  const [mode, setMode] = useState('right');
  const [jsonObj, setJsonObj] = useState();
  const [error, setError] = useState();
  const [show, setShow] = useState(false);

  const handleJsonChange = debounce((e) => {
    try{
      setJsonObj(JSON.parse(e.target.value || "{}"));
      setError(undefined);
    }catch(error){
      setError(error);
      setJsonObj(undefined);
    }
  },200)

  const handleToggle = () => {
    setMode(mode === 'left' ? 'right' : 'left');
  }

  const handleParseUrl = () => {
    setShow(true)
  }

  const style = mode === 'left' ? { width: 0, padding: 4 } : { flex:1, width:'100%' };
  const status = error ? 'error' : '';


  return <Layout className='JSONPrettier'>
    <div className='JSONPrettier-header'>
      <h4 style={{ margin: '4px',   lineHeight: '38px'}}>JSON Prettier</h4>
      <Popover 
        placement="bottomRight"
        title="说明" 
        content={<div className='JSONPrettier-popover-content'>
          <p>- 任何大于 2^53 - 1 即 9007199254740991 的值可能会丢失精确度。</p>
          <p>- 任何小于 -2^53 + 1 即 -9007199254740991 的值可能会丢失精确度。</p>
        </div>}
      >
        <QuestionCircleOutlined style={{ fontSize: '14px'}}/>
      </Popover>
      <Button 
        type="primary" 
        onClick={handleParseUrl}
        size='small'
        style={{ margin: '0 4px'}}
      >解析 URL</Button>
      { error && <Alert type="error" message={String(error)} banner style={{ marginLeft: '12px'}}/>}
    </div>
    <div className='JSONPrettier-content'>
      <div className='JSONPrettier-content-item' style={style}>
          <TextArea rows={24} onChange={handleJsonChange} status={status} placeholder="请输入 JSON 字符串"/>
      </div>
      <div className='JSONPrettier-content-divider'>
        <Button onClick={handleToggle} >
          {
            mode === 'left' ? <VerticalLeftOutlined /> : <VerticalRightOutlined />
          }
        </Button>
      </div>
      <div className='JSONPrettier-content-item' style={{ flex: 1}}>
        <ReactJson src={jsonObj} displayDataTypes={false}/>
      </div>
    </div>
    <Modal 
      open={show} 
      width="80%" 
      onCancel={() => setShow(false)}
      footer={false}
      destroyOnClose
      maskClosable={false}
    >
      <URLPrettier />
    </Modal>
  </Layout>
  }

export default JSONPrettier;