import './index.css';

const SvgIconButton = (IconComponent) => {
  return ({ 
    isSelected,
    className = '',
    style
  }) => <div 
    style={{ 
      ...(style || {}),
      backgroundColor: isSelected ? '#fff' : 'transparent'
    }} 
    className={`svgIconButton ${className} ${isSelected ? 'svgIconSelected' : ''}`}
  >
    <IconComponent
      stroke={ isSelected ? 'none' : '#8a8a8a'}
    />
  </div>
}

export default SvgIconButton;