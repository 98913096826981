import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom/dist';
import { Analytics } from '@vercel/analytics/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>
  <Analytics />
  {/* StrictMode 会导致 useEffect 执行两次 */}
  {/* <React.StrictMode> */}
    <BrowserRouter basename=''>
      <App />
    </BrowserRouter>
  {/* </React.StrictMode> */}
  </>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
