import React, { useCallback, useMemo, useState } from 'react';
import { Input, Button, message } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { base64ToString, stringToBase64 } from '../../../../utils/code';
import './base64.css'

const { TextArea } = Input;
const Base64Code = () => {
  const [mode, setMode] = useState('encode');
  const [incodeValue, setIncodeValue] = useState('');
  const [outcodeValue, setOutcodeValue] = useState('');

  const handleClick = useCallback(() => {
    if (mode === 'encode') {
      const encodeValue = stringToBase64(incodeValue);
      setOutcodeValue(encodeValue);
    }else{
      const decodeValue = base64ToString(outcodeValue, true);
      setIncodeValue(decodeValue);
    }
  },[incodeValue,outcodeValue, mode])

  const handleToggleMode = useCallback(() => {
    setMode(mode === 'encode' ? 'decode' : 'encode');
  },[mode])

  const handleClear = useCallback(() => {
    setIncodeValue('');
    setOutcodeValue('');
  },[])

  const encodeMode = useMemo(() => mode === 'encode', [mode]);
  const encodeModeText = useMemo(() => encodeMode ? '编码' : '解码',[encodeMode])

  return <div className='route-decode-base64'>
    <div className='route-decode-base64-title'>
      <p>{`进行 Base64 ${encodeModeText}`}</p>
    </div>
    <div className='route-decode-base64-input' style={{ flexDirection: encodeMode ? 'row' : 'row-reverse'}}>
      <TextArea rows={12} value={incodeValue} onChange={(e) => setIncodeValue(e.target.value.trim())} placeholder='请输入'/>
      <TextArea rows={12} value={outcodeValue} onChange={(e) => setOutcodeValue(e.target.value.trim())} placeholder='请输入'/>
    </div>
    <div className='route-decode-base64-btn'>
      <Button type="primary" onClick={handleToggleMode}>切换模式</Button>
      <Button type="primary" onClick={handleClick}>{encodeModeText}</Button>
      <Button type="primary" onClick={handleClear}>清空</Button>
      <CopyToClipboard text={encodeMode ? outcodeValue : incodeValue}
          onCopy={() => message.success('复制成功')}>
          <Button type='primary'>复制结果</Button>
        </CopyToClipboard>
    </div>  
  </div>
}

export default Base64Code;