import React, { useEffect } from 'react';
import Axios from 'axios';

const InstantIDPage = () => {
  const getOutput = async () => {
    const url = 'https://next.eatmango.cn/api/proxy/replicate';
    const params = {
      model:'zsxkib/instant-id:4a86d2bcb78e94f5fcda66492b1acdf8781b252fbe55714fb67b30e66c4512f4',
      input:{
        image: "https://replicate.delivery/pbxt/KI4a4NSCc0sAFMdlbD4n8ufHLXb2utKIwwbQff0M2ryhGJPJ/pp_0.jpg",
        width: 640,
        height: 640,
        prompt: "4k photo of a man. highly detailed, best quality, shallow depth of field, fuji film",
        sdxl_weights: "stable-diffusion-xl-base-1.0",
        guidance_scale: 5,
        negative_prompt: "ugly, (lowres, low quality, worst quality:1.2), (text:1.2), watermark, painting, drawing, illustration, glitch, deformed, mutated, cross-eyed, ugly, disfigured (lowres, low quality, worst quality:1.2), (text:1.2), watermark, painting, drawing, illustration, glitch,deformed, mutated, cross-eyed, ugly, disfigured",
        ip_adapter_scale: 0.8,
        num_inference_steps: 30,
        controlnet_conditioning_scale: 0.8
      }
    }
   return await Axios.post(url, params)
  }

  useEffect(() => {
    const fetch = async() => {
      try{
        const output = getOutput()
        console.log('output==>', output)
      } catch(error){
        console.log('getOutput error ==>', error)
      }
    }
    fetch()
  },[])

  return <div>
    <p>接口请求需要预计 20s, 但是目前 timeout 是 10s, 等待优化。</p>
  </div>
}

export default InstantIDPage;