import { ReactComponent as CropSvg } from '../../../../../../assets/icons/image-editor-icons/ic-crop.svg'
import SvgIconButton from '../SvgIconButton';
import './index.css';

const CropIcon = SvgIconButton(CropSvg)
const Actions = () => {
  return <div className='actions'>
    <CropIcon />
  </div>
}

export default Actions;