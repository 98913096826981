import { useCallback, useEffect, useRef } from "react"
import ImageEditor from '../image-editor'

import './index.css'

const ImageEditorReact = (props) => {
  const imageEditorInst = useRef();
  const isEventHandlerKeys = (key) => {
    return /on[A-Z][a-zA-Z]+/.test(key);
  }

  const bindEventHandlers = useCallback(() => {
    Object.keys(props)
      .filter(isEventHandlerKeys)
      .forEach((key) => {
        const eventName = key[2].toLowerCase() + key.slice(3);
        imageEditorInst.current.off(eventName);
        imageEditorInst.current.on(eventName, props[key]);
      });
  },[props])

  const unbindEventHandlers = useCallback(() => {
    Object.keys(props)
      .filter(isEventHandlerKeys)
      .forEach((key) => {
        const eventName = key[2].toLowerCase() + key.slice(3);
        imageEditorInst.current.off(eventName);
      });
  },[props])

  // 挂载
  useEffect(() => {
    imageEditorInst.current = new ImageEditor(
      '#image-editor', 
      props
    );

    return () => {
      imageEditorInst.current.destroy();
      imageEditorInst.current = null;
    }
  // 仅执行一次
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // 事件绑定/卸载
  useEffect(() => {
    bindEventHandlers(props);
    return () => {
      unbindEventHandlers();
    }
  },[props, bindEventHandlers, unbindEventHandlers])

  return <div id="image-editor"></div>
}

export default ImageEditorReact