import React, { useCallback, useState, useMemo } from 'react'
import pinyin from 'pinyin'
import { Input, Form, Select, Button, Switch} from 'antd'
import { debounce } from 'lodash-es';
import { pyModes, pyStyles } from './config';

import './style.css';

// export interface IPinyinOptions {
//   style?: IPinyinStyle; // 拼音输出形式
//   mode?: IPinyinMode, // 拼音模式
//   // 指定分词库。
//   // 为了兼容老版本，可以使用 boolean 类型指定是否开启分词，默认开启。
//   segment?: IPinyinSegment | boolean;
//   // 是否返回多音字
//   heteronym?: boolean;
//   // 是否分组词组拼音
//   group?: boolean;
//   compact?: boolean;
// }

const { TextArea } = Input;
const FormItem = Form.Item;
const DEFAULT_OPTION = {
  style:'STYLE_TONE',
  mode:'normal',
  segment:false,
  group: false,
  compact: true,
  heteronym: false
};
const PinyinTool = () => {
  const [form] = Form.useForm()
  const [spaceMode,setSpaceMode] = useState(true)
  const [value, setValue] = useState('')
  const [pyVal, setPyVal] = useState([])
  // const [option, setOption] = useState(DEFAULT_OPTION)

  // const handleOptionChange = useCallback((attr) => {
  //   const newOption = {
  //     ...option,
  //     ...attr
  //   }
  // },[option])

  const handleTransform = debounce(() => {
    const options = form.getFieldsValue();
    // console.log('options==>', options);
    const { style, group } = options;
    const result = pinyin(value, {
      ...options,
      segment: group,
      style: pinyin[style]
    });
    // console.log('result==>', result)
    setPyVal(result);
  },300)

  const toggleSpace = useCallback(() => {
    setSpaceMode(!spaceMode)
  },[spaceMode])

  const handleChange = useCallback((e) => {
    const { value } = e.target
    setValue(value)
  },[handleTransform])

  const content = useMemo(() => pyVal.map((items) => items.join(spaceMode ? ' ' : '')),[pyVal, spaceMode]);

  return <div className="pinyin">
    <div className='pinyinContent'>
        <p className='pinyinItemTitle'>输入汉字</p>
        <TextArea rows={8} value={value} onChange={handleChange}/>
        <div>
        <Button 
          onClick={handleTransform} 
          type="primary" 
          style={{ margin: '8px 0'}}
        >转换成拼音
        </Button>
        <Button 
          onClick={toggleSpace} 
          type="primary" 
          style={{ margin: '8px 0 0 8px'}}
        >
          { spaceMode ? '关闭空格显示' : '开启空格显示' }
        </Button>
        </div>
        <div className='pinyinResult'>{
          content.map((val, index) => <p className='pinyinResultItem' key={index}>{val}</p>)
        }</div>
    </div>
    <div className='pinyinOptions'>
      <p className='pinyinItemTitle'>配置选项</p>
      <Form
        initialValues={DEFAULT_OPTION}
        form={form}
        wrapperCol={{span:16}}
        labelCol={{ span: 8}}
        size="small"
      >
        <FormItem label="Style" name="style">
          <Select options={pyStyles} />
        </FormItem>
        <FormItem label="Mode" name="mode">
          <Select options={pyModes} />
        </FormItem>
        <FormItem label="多音字" name="heteronym" valuePropName="checked">
          <Switch />
        </FormItem>
        <FormItem label="分组词组" name="group" valuePropName="checked">
          <Switch />
        </FormItem>
        <FormItem label="紧凑显示" name="compact" valuePropName="checked">
          <Switch />
        </FormItem>
      </Form>
    </div>
  </div>
}

export default PinyinTool

// TODO: 音调快捷复制