import React, { useCallback, useEffect, useState } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import { Layout, Menu, ConfigProvider } from 'antd';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';
import { useNavigate, useLocation } from 'react-router-dom';
import { navs } from './constant/header';
// import Home from './route/home';
import JSONPrettier from './route/prettier/route/json/JSONPrettier.jsx';
import URLPrettier from './route/prettier/route/url/URLPrettierPage.jsx';
import Excalidraw from './route/prettier/route/excalidraw/Excalidraw.jsx';
import ImageEditor from './route/image/route/ImageEditor/index.jsx';
import InstantIDPage from './route/ai/index.jsx';
import DecodePrettier from './route/prettier/route/decode/base64.jsx';

import { findParentRoute } from './utils/route';
import PinyinTool from './route/image/route/pinyin/index.jsx';
import Main from './route/main/index.jsx';
import EncodeUrl from './route/prettier/route/encode/url/index.jsx';

const { Header, Content, Footer } = Layout;

const App = (props) => {
  const navigate = useNavigate();
  const location = useLocation()

  const [activeKey, setActiveKey] = useState('/prettier/json');

  useEffect(() => {
    const { pathname = '/'} = location;
    setActiveKey(findParentRoute(pathname));
  },[location])

  const handleSelect = useCallback(({ item, key, keyPath}) => {
    // console.log(item, key, keyPath);
    navigate(keyPath[0])
  },[navigate])

  return (
    <ConfigProvider locale={zhCN}>
    <Layout className="layout">
      <Header style={{ display: 'flex', alignItems: 'center', background:'#fff' }} >
        <div className="demo-logo" />
        <Menu
          // theme="dark"
          mode="horizontal"
          items={navs}
          style={{ flex: 1}}
          selectedKeys={activeKey}
          onSelect={handleSelect}
        />
      </Header>
      <Content style={{ padding: '12px', overflowY: 'scroll' }}>
        <Routes>
          <Route 
            exact
            path="/"
            element={<Main />}
          />
          <Route exact path="/prettier">
            <Route exact path="/prettier/json" element={<JSONPrettier/>} />
            <Route exact path="/prettier/url" element={<URLPrettier/>} />
            <Route exact path="/prettier/decode" element={<DecodePrettier/>} />
            <Route exact path="/prettier/encode">
              <Route exact path="/prettier/encode/url" element={<EncodeUrl />} />
            </Route>
          </Route>
          <Route exact path="/workflow">
            <Route exact path="/workflow/excalidraw" element={<Excalidraw/>} />
          </Route>
          <Route exact path="/media">
            <Route path="/media/pinyin" element={<PinyinTool />} />
            <Route path="/media/image-editor" element={<ImageEditor />} />
          </Route>
          <Route exact path="/aispace">
            <Route exact path="/aispace/instantid" element={<InstantIDPage/>} />
          </Route>
        </Routes>
      </Content>
      <Footer style={{ 
          display:'flex', 
          alignItems:'center', 
          justifyContent:'center',
          padding:'16px 20px',
        }}
      > 
        <img src="/logo.png" width="16px" height="16px" alt="logo"/> 
        ©2024 EATMANGO-实用工具
      </Footer>
    </Layout>
    </ConfigProvider>);
};

export default App;