import { ToolOutlined, NodeIndexOutlined, HomeOutlined } from '@ant-design/icons';

export const navs = [
  {
    key: '/',
    label: '首页',
    icon: <HomeOutlined />
  },
  // {
  //   key: '/',
  //   label: '每日一句',
  //   icon: <CoffeeOutlined />
  // },
  {
    key: '/prettier',
    label: '格式化工具',
    icon: <ToolOutlined />,
    children:[
      {
        key:'/prettier/json',
        label:'JSON 解析',
      },
      {
        key:'/prettier/url',
        label:'URL 解析',
      },
      {
        key:'/prettier/encode/url',
        label:'URL 生成',
      },
      {
        key:'/prettier/decode',
        label:'Base64工具',
      },
    ]
  },
  {
    key: '/workflow',
    label: '工作流工具',
    icon: <NodeIndexOutlined />,
    children:[
      {
        key:'/workflow/excalidraw',
        label:'Excalidraw',
      },
    ]
  },
  {
    key: '/media',
    label: '多媒体工具',
    icon: <NodeIndexOutlined />,
    children:[
      {
        key:'/media/pinyin',
        label:'拼音工具',
      },
      {
        key:'/media/image-editor',
        label:'ImageEditor',
      },
    ]
  },
  {
    key: '/aispace',
    label: 'AI工具',
    icon: <NodeIndexOutlined />,
    children:[
      {
        key:'/aispace/instantid',
        label:'instantid',
      },
    ]
  }
]